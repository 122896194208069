import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
// import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar'; 


const tagManagerArgs = {
  gtmId: 'GTM-KT56TNP',
  dataLayerName: 'PageDataLayer'
}

hotjar.initialize(1599193, 6);

TagManager.initialize(tagManagerArgs);

ReactGA.initialize('UA-142076519-3');
ReactGA.pageview(window.location.pathname + window.location.search);


ReactDOM.render(<App />, document.getElementById('root'));