import React, { Component } from 'react';
import {Redirect } from 'react-router';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import Loadable from 'react-loadable';
import './App.scss';
import {UserContext} from './user_context';
import { fetch as fetchPolyfill } from 'whatwg-fetch';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading
});

const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading
});

const Recover = Loadable({
  loader: () => import('./views/Pages/Recover'),
  loading
});

const RecoverReset = Loadable({
  loader: () => import('./views/Pages/RecoverReset'),
  loading
});

const Register = Loadable({
  loader: () => import('./views/Pages/Register'),
  loading
});
const RegisterNM = Loadable({
  loader: () => import('./views/Pages/RegisterNM/RegisterNM'),
  loading
});

const RodoConfirm = Loadable({
  loader: () => import('./views/Pages/RodoConfirm'),
  loading
});

function PrivateRoute({component: Component, authed, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) => authed === true
        ? <Component {...props} />
        : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
    />
  )
}

class App extends Component {

  constructor(props) {
    super(props);

    this.updateUser = (newUser) => {
      this.setState({user: newUser});
    };

    this.updateDeviceTrusted = (newFlag) => {
      this.setState({deviceTrusted: newFlag});
    };

    this.state = {
      loading: true,
      user: null,
      updateUser: this.updateUser,
      deviceTrusted: false,
      updateDeviceTrusted: this.updateDeviceTrusted,
    };
  }

  componentDidMount() {
    fetchPolyfill('/user_check')
      .then(response => response.json())
      .then(data => {
        this.setState({user: data.user,
                       deviceTrusted: data.device_trusted,
                       loading: false});
      });
  }

  render() {
    if (this.state.loading) {
      return <div className="animated fadeIn pt-1 text-center">Loading...</div>;
    }
    return (
      <UserContext.Provider value={this.state}>
        <HashRouter>
            <Switch>
              <Route exact path="/login" name="Login Page" component={Login} />
              <Route exact path="/recover" name="Recover page" component={Recover} />
              <Route exact path="/register/user/:site?" name="Rejestracja" component={Register} />
              <Route exact path="/register-nm/user/:site?" name="Rejestracja" component={RegisterNM} />
              <Route exact path="/recover/reset/:code" name="Recover rest password page" component={RecoverReset} />
              <Route exact path="/rodo/confirm/:code" name="Potwierdzenie zgody RODO" component={RodoConfirm} />
              <Route exact path="/404" name="Page 404" component={Page404} />
              <Route exact path="/500" name="Page 500" component={Page500} />
              <PrivateRoute authed={this.state.user !== null} path="/" name="Home" component={DefaultLayout} />
            </Switch>
        </HashRouter>
      </UserContext.Provider>
    );
  }
}

export default App;
